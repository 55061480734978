import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import styles from "./email-signup.module.scss"

const EmailSignup = ({secondary}) => {
    let email
    const url = 'https://invirsa.us2.list-manage.com/subscribe/post?u=5d59d5b8a36d19940c76b4c34&amp;id=c53ea57eb0';
    
    const submitForm = (e, callback) => {
        e.preventDefault()
        callback({
            EMAIL: email.value
        })
    }

    return (
        <div className={secondary ? styles.secondary : ''}>
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
            <div>
                <form onSubmit={(e) => submitForm(e, subscribe)} className={styles.form}>
                    <input
                        type="email"
                        placeholder="Email"
                        ref={node => (email = node)}
                        required
                        ></input>
                    <input type="submit" value="Sign Up"></input>
                    <div className={styles.response}>
                        {status === "sending" && <div className={styles.spinner}></div>}
                        {status === "error" && <div className={styles.fail} dangerouslySetInnerHTML={{__html: message}}/>}
                        {status === "success" && <div className={styles.success}>You're signed up, we'll keep you up to date!</div>}
                    </div>
                </form>
            </div>
            )}
        />
        </div>
    )
}

export default EmailSignup
import React from 'react'
import styles from './hero-text.module.scss'

const HeroText = ({children}) => {
    return (
        <div className={styles.main}>
            {children}
        </div>
    )
}

export default HeroText
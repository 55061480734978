import React from 'react'
import Header from './header'
import HomeHeader from './home-header'
import Footer from "./footer";
import Body from "./body";
import { useEffect } from 'react';

const MainLayout = (props) => {

    const toggleLock = () => {
        document.querySelector('html').classList.toggle('locked');
    }

    useEffect(() => {
        const html = document.querySelector('html');
        if(html) {
            html.classList.remove('locked')
        }
    });

    return (
        <div>
            {props.homeHeader && <HomeHeader onClick={toggleLock}></HomeHeader>}
            {!props.homeHeader && <Header></Header>}
            <Body>
                {props.children}
            </Body>
            <Footer></Footer>
        </div>
    )
}

export default MainLayout
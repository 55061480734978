import React from 'react'

const Body = ({children}) => {
    return (
        <main>
            {children}
        </main>
    )
}

export default Body
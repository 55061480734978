import React from 'react'
import styles from './home-header.module.scss'
import { Link } from "gatsby";
import { useState } from 'react';
import { useEffect } from 'react';

const HomeHeader = ({onClick}) => {
    const [navOpen, setNavOpen] = useState(false);
    const [classes, setClasses] = useState(styles.wrapper);
    const [isTop, setIsTop] = useState(true);

    const onScroll = function() {
        if (window.pageYOffset > 100) {
            setIsTop(false);
        } else {
            setIsTop(true);
        }
    }

    useEffect(() => {
        let c = navOpen ? `${styles.wrapper} ${styles.active}` : styles.wrapper;
        c += isTop ? '' : ' ' + styles.scrolling;
        setClasses(c);
    }, [navOpen, isTop])
    

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    })

    const handleClick = (e) => {
        setNavOpen(!navOpen);
        onClick();
    }

    return (
        <div className={classes}>
            <div className="container flex justify-between items-center">
                <div className={styles.logo}>
                    <img src="/invirsa_logo.svg" alt="Invirsa"/>
                </div>
                <nav role="navigation" aria-label="main navigation" className={styles.nav}>
                    <button
                        aria-label="Open Navigation"
                        className={navOpen ? styles.bars + ' ' + styles.active : styles.bars}
                        onClick={handleClick}
                    ></button>
                    <ul className={navOpen ? styles.active : ''}>
                        <li>
                            <Link to="/science">Science</Link>
                        </li>
                        <li>
                            <Link to="/leadership">Leadership</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default HomeHeader
import React from 'react'
import { Link } from "gatsby";
import EmailSignup from '../components/email-signup';
import styles from './footer.module.scss'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={`md:grid grid-cols-3 gap-12 mb-12 ${styles.cols}`}>
                    <div>
                        <h5>Links</h5>
                        <ul>
                            <li>
                                <Link className={styles.link} activeClassName={styles.linkActive} to="/science">Science</Link>
                            </li>
                            <li>
                                <Link className={styles.link} activeClassName={styles.linkActive} to="/leadership">Leadership</Link>
                            </li>
                            <li>
                                <Link className={styles.link} activeClassName={styles.linkActive} to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    
                    <div>
                        <h5>Contact</h5>
                        <p>Invirsa<br />1275 Kinnear Rd.<br />Columbus, Ohio 43212</p>
                        <p>Email: info@invirsa.com</p>
                    </div>

                    <div>
                        <h5>Get Invirsa Updates</h5>
                        <EmailSignup secondary={true}/>
                    </div>
                </div>

                <div className={styles.copyright}>
                    Copyright 2020 Invirsa. Privacy Policy
                </div>
            </div>
        </footer>
    )
}

export default Footer
import React from 'react'
import styles from './header.module.scss'
import { Link } from "gatsby";
import { useState } from 'react';
import { useEffect } from 'react';

const Header = () => {
    const [navOpen, setNavOpen] = useState(false);
    const [classes, setClasses] = useState(styles.wrapper);
    const [isTop, setIsTop] = useState(true);

    const onScroll = function() {
        if (window.pageYOffset > 50) {
            setIsTop(false);
        } else {
            setIsTop(true);
        }
    }

    useEffect(() => {
        let c = navOpen ? `${styles.wrapper} ${styles.active}` : styles.wrapper;
        c += isTop ? '' : ' ' + styles.scrolling;
        setClasses(c);
    }, [navOpen, isTop])
    

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    })

    const onClick = (e) => setNavOpen(!navOpen);

    return (
        <div className={classes}>
            <div className="container flex justify-between items-center">
                <div className={styles.logo}>
                    <Link to="/">
                        <img src="/invirsa_logo.svg" alt="Invirsa"/>
                    </Link>
                </div>
                <nav role="navigation" className={styles.nav}>
                    <button aria-label="Toggle menu" className={navOpen ? styles.bars + ' ' + styles.active : styles.bars} onClick={onClick}></button>
                    <ul className={navOpen ? styles.active : ''}>
                        <li>
                            <Link to="/science">Science</Link>
                        </li>
                        <li>
                            <Link to="/leadership">Leadership</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header